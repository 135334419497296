html,
body {
  font-family: "Poppins-Regular", system-ui, -apple-system, Segoe UI, Roboto,
    Ubuntu, Cantarell, Noto Sans, sans-serif, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #3d3d3c;
}

input {
  outline: none !important;
}

.wrapper {
  padding-left: 260px !important;
  padding-right: 260px !important;
  max-width: 1680px !important;
  margin: auto !important;
  @media (max-width: 1280px) {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  @media (max-width: 768px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.mob-object-right {
  @media (max-width: 768px) {
    object-position: 65% !important;
  }
}
@mixin letter-spacing($spacing) {
  letter-spacing: calc(-1em * 0.001 * #{$spacing});
}

.heading-light {
  font-size: responsive 30px 45px !important;
  line-height: responsive 40px 60px !important;
  font-family: "Poppins-Light", sans-serif !important;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
.a {
  text-decoration: none !important;
}

h1,
.h1 {
  font-size: responsive 30px 45px !important;
  line-height: responsive 40px 60px !important;
  font-family: "Poppins-Bold", sans-serif !important;
}

h2,
.h2 {
  font-size: responsive 18px 25px;
  line-height: responsive 22px 32px;
  font-family: "Poppins-Light", sans-serif !important;
}

h3,
.h3 {
  font-size: responsive 17px 20px;
  line-height: responsive 17px 20px;
}

h4,
.h4 {
  font-size: responsive 20px 25px;
  line-height: responsive 20px 25px;
}

h5,
.h5 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

h6,
.h6 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

p,
.p {
  font-family: "Poppins-Light", sans-serif !important;
  font-size: responsive 14px 17px;
  line-height: responsive 15px 22px;
  font-family: "work-sans", sans-serif;
}

a {
  p {
    font-family: "Poppins-Regular", sans-serif !important;
  }
}

.intro-text-heading {
  color: #ffe000;
  font-weight: bold !important;

  strong {
    color: #c5e3d3 !important;
    font-weight: bold !important;
  }
}

.accordian {
  padding-left: 140px;
  padding-right: 140px;
  @media (max-width: 1280px) {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  @media (max-width: 768px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  details:hover {
    background-color: #c5e3d3;
    font-weight: bold !important;
    transition: all 0.2s ease-in-out;
  }
  details {
    cursor: pointer;
    background-color: #f0f0f0;
    border-radius: 30px;
    @media (max-width: 1280px) {
      border-radius: 20px;
      margin-top: 20px;
    }
    @media (max-width: 768px) {
      margin-top: 10px;
    }
    margin-top: 35px;
    padding-left: 120px;
    padding-right: 120px;
    margin-bottom: 20px;
    @media (max-width: 1280px) {
      padding-left: 70px !important;
      padding-right: 70px !important;
    }
    @media (max-width: 768px) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
  details[open] > summary {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  summary {
    padding-top: 50px;
    padding-bottom: 50px;
    @media (max-width: 1280px) {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }
    @media (max-width: 768px) {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
  }

  details[open] {
    background-color: #c5e3d3 !important;
    font-weight: bold !important;
    padding-bottom: 40px;
    .accord-arrows::before {
      content: "Hide answer -";
      @media (max-width: 768px) {
        content: "-";
        font-size: 20px;
      }
    }
    -webkit-appearance: none;
  }

  .accord-arrows::before {
    content: "Read answer +";
    @media (max-width: 768px) {
      content: "+";
      font-size: 20px;
    }
  }

  details > summary {
    list-style: none;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }
}

.title-thin {
  font-family: "Poppins-Light", sans-serif !important;
}

/* ---- flickity-button ---- */

.flickity-button {
  outline: none !important;
  position: absolute;
  background: hsla(0, 100%, 38%, 0);
  border: 1px solid #3d3d3c;
  color: #3d3d3c;
}

.flickity-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

.flickity-button {
  svg {
    height: 14px !important;
    width: auto;
    margin-top: 5px;
  }
  top: auto !important;
  bottom: 0 !important;
  margin-bottom: 165px;

  @media (max-width: 1280px) {
    margin-bottom: -45px;
    margin-left: 70px;
  }
  @media (max-width: 768px) {
    margin-left: 20px;
  }
}

.flickity-prev-next-button {
  bottom: 0;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 0px;
}
.flickity-prev-next-button.next {
  left: 70px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 25%;
  width: 60%;
  height: 60%;
}

.animation-pr {
  padding-right: 250px !important;
  margin-top: 975px !important;
  @media (max-width: 1400px) {
    padding-right: 100px !important;
    margin-top: 1000px !important;
  }
}

.nocursor {
  cursor: none !important;
}

@media (hover: hover) and (pointer: fine) {
  #cursor {
    z-index: 99;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    will-change: transform;
  }

  #cursor .cursor--inner {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background: #ffe000;
  }
}

.privacy-dots {
  ul {
    margin-top: 20px;
  }
  li {
    list-style: circle !important;
    margin-left: 30px;
  }
  h3 {
    margin-bottom: 10px;
  }
}
