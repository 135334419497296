.headroom--unpinned,
.headroom--pinned,
.headroom {
  padding-top: 15px;
  height: 90px;
  background-color: #fffffff0;
  margin: auto !important;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 250ms;
  padding-left: 30px !important;
  padding-right: 30px !important;
  filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.2));
  .logo {
    height: 60px !important;
    width: auto !important;
  }
  .logo-text {
    display: none !important;
  }

  @media (max-width: 1024px) {
    padding-top: 10px;
    background-color: #ffffff;
    height: 80px !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
  }
  @media (max-width: 770px) {
    height: 80px !important;
  }
}

a {
  text-decoration: none !important;
  p {
    font-family: "AlbertSans-Variable", sans-serif;
    font-size: responsive 14px 17px;
  }
}

.headroom--top {
  padding-top: 0px;
  height: 125px;
  .logo-text {
    display: block !important;
  }
  filter: none;
  box-shadow: none;
  display: grid;

  .logo {
    height: 75px !important;
  }

  @media (max-width: 1280px) {
    padding-right: 70px !important;
    padding-left: 70px !important;
  }

  @media (max-width: 1024px) {
    .nav-button {
      height: 40px !important;
      width: 110px !important;
    }
    height: 90px !important;
    padding-right: 40px !important;
    padding-left: 40px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 75px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
  }

  @media (max-width: 770px) {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  padding-left: 140px !important;
  padding-right: 140px !important;
  background-color: #ffffff00;
}

/* flickity-fade */

.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1;
}

.gallery-cell:focus {
  outline: none !important;
}

.gallery-cell {
  left: 0 !important;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
  transform: none !important;
}

.gallery-cell.is-selected {
  opacity: 1;
  z-index: 0;
}

#main-header {
  background-color: rgba(255, 255, 255, 0.7);
}
